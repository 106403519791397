<template>
  <div style="min-height: calc(100vh - 158px);" class="card">
    <div style="background: #DBE7F2" class="card p-2 mb-1">
      <TitleButton :showBtn="false" title="Stock Adjustment"/>

      <DateQuerySetter :inEnoughSpace="false" btnTitle="Go" @onClickGo="stockAdjustmentList">
        <div class="col-12 col-sm-6 col-md-4 col-lg-3">
          <label for="colFormLabel" class="col-form-label"> Product/Service </label>
          <AsyncSelect placeholder="Select Product/Service" :api-service="fetchProductList"
                       v-model="productId" label="text" :reduce="text => text.id"
                       :format-label="option => option.text"/>
        </div>
        <div class="col-12 col-sm-6 col-md-4 col-lg-3">
          <label for="colFormLabel" class="col-form-label">Cost Center: </label>
          <v-select placeholder="Select Cost Centre" v-model="costCentreId" :options="costCentres" label="name"
                    :reduce="name => name.id"/>
        </div>
        <div class="col-12 col-sm-6 col-md-4 col-lg-3">
          <label for="colFormLabel" class="col-form-label">Project</label>
          <v-select placeholder="Select Project" v-model="projectId" :options="projects" label="name"
                    :reduce="name => name.id"/>
        </div>
        <div class="col-12 col-sm-6 col-md-4 col-lg-3">
          <label for="colFormLabel" class="col-form-label"> Warehouse </label>
          <v-select placeholder="Select Inventory Location" v-model="warehouseId" :options="locations" label="text"
                    :reduce="text => text.id"/>
        </div>
      </DateQuerySetter>
    </div>

    <div class="col-12">
      <div class="d-flex justify-content-end px-2">
        <button
            @click="exportTable"
            class="btn btn-outline-secondary btn-sm btn-download-invoice waves-effect"
        >
          Export
        </button>
      </div>
    </div>

    <div class="card mt-1 px-2">
      <ListTable :tableItems="stockAdjustments"/>
    </div>

    <div class="px-2">
      <Pagination :offset="offset" @onPageChange="onPageChange" ref="pagination"/>
    </div>
    <Loader v-if="reportLoading"/>
  </div>
</template>

<script setup>
import {onMounted, ref, defineEmits, computed} from 'vue';
import TitleButton from '@/components/atom/TitleButton'
import DateQuerySetter from '@/components/atom/DateQuerySetter'
import AsyncSelect from "@/components/molecule/input-field/AsyncSelect.vue";
import handleReport from '@/services/modules/inventory';
import handleBusinessesLocations from '@/services/modules/businessesLocations';
import handlePurchase from '@/services/modules/purchase';
import figureFormatter from "@/services/utils/figureFormatter";
import handleProjects from "@/services/modules/procurement/project";
import handleCostCentres from "@/services/modules/ngo/costCentre";
import {useRoute, useRouter} from "vue-router";
import ListTable from "@/components/molecule/company/stock-report/StockAdjustmentTable.vue";
import Pagination from '@/components/atom/Pagination'
import {useStore} from "vuex";

const {fetchBusinessLocationsList} = handleBusinessesLocations();
const {getStockAdjustmentReport, reportLoading} = handleReport();
const {fetchProductList} = handlePurchase();
const {fetchProjects} = handleProjects();
const {fetchCostCentreList} = handleCostCentres();
const {commaFormat} = figureFormatter();

const {showError, showSuccess} = defineEmits();
const $router = useRouter()
const $route = useRoute()
const $store = useStore()

const productId = ref(null);
const warehouseId = ref(null);
const costCentreId = ref(null);
const projectId = ref(null);

const locations = ref([]);
const products = ref([]);
const costCentres = ref([]);
const projects = ref([]);

const stockAdjustments = ref([]);
const offset = 20;

const companyId = computed(() => $route.params.companyId);
const start = computed(() => $route.query.start);
const end = computed(() => $route.query.end);
const page = computed(() => $route.query.page);
const token = computed(() => localStorage.getItem('token'));

const exportTable = () => {
  let query = getQuery();
  let url = `${process.env.VUE_APP_BASE_URL}/export/inventory/stock-adjustment${query}&_token=${token.value}`;
  let a = document.createElement("a");
  a.setAttribute("href", url);
  a.click();
};

const getQuery = () => {
  let query = '?company_id=' + companyId.value
  if (!!productId.value) query += '&product_id=' + productId.value
  if (!!warehouseId.value) query += '&warehouse_id=' + warehouseId.value
  if (!!costCentreId.value) query += '&cost_centre_id=' + costCentreId.value
  if (!!projectId.value) query += '&project_id=' + projectId.value
  if (!start.value && !end.value) return query
  if (start.value) query += '&start_date=' + start.value
  if (end.value) query += '&end_date=' + end.value
  if (page.value) query += '&page=' + page.value
  query += '&offset=' + offset
  return query
};

const onPageChange = page => {
  let routeQuery = Object.assign({}, $route.query)
  routeQuery.page = page
  $router.push({path: $route.path, query: routeQuery})
  setTimeout(() => {
    stockAdjustmentList();
  }, 100)
};

const setPagination = (data) => {
  setPaginationData({
    records: data.total,
    perPage: data.per_page,
    currentPage: data.current_page,
  })
};

const setPaginationData = (data) => {
  $store.commit('setPaginationData', data)
};

const resetPagination = () => {
  $store.commit('resetPagination')
};

async function stockAdjustmentList() {
  try {
    const res = await getStockAdjustmentReport(getQuery());
    if (!res.status) {
      stockAdjustments.value = [];
      return resetPagination()
    }
    const {data, ...paginationData} = res.data;
    stockAdjustments.value = data;
    await setPagination(paginationData)
  } catch (err) {
    if (err.response) {
      showError(err.response.message)
    }
  }
}

onMounted(async () => {
  const companyQuery = `?company_id=${companyId.value}`;
  let projectQuery = companyQuery + '&with_donors=1';
  try {
    const [businessLocationRes, projectsRes, costCentresRes] = await Promise.all([
      fetchBusinessLocationsList(companyQuery),
      fetchProjects(projectQuery),
      fetchCostCentreList(companyQuery)
    ]);
    if (businessLocationRes.data) {
      locations.value = businessLocationRes.data;
      locations.value.unshift({
        id: '',
        text: 'All'
      });
    }
    if (projectsRes.data) {
      projects.value = projectsRes.data;
    }
    if (costCentresRes.data) {
      costCentres.value = costCentresRes.data;
    }
    location.value = 'all';
    reportLoading.value = false;
  } catch (error) {
    reportLoading.value = false;
  }
  await stockAdjustmentList()
});
</script>


<style scoped>
.head td {
  text-align: right;
}
</style>
